import { ButtonGeneral, ButtonType } from '@components/general/button-general';
import { InfoIcon } from '@components/general/icons/roadmap';
import { ContentBox } from '@components/general/layouts/content-box';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { Maybe } from '@gql/generated/generated';
import { Tooltip } from '@shopify/polaris';
import { getRoadmapProgressColor } from '@utils/misc';
import React, { useState } from 'react';
import { Platform, Text, TouchableOpacity, View } from 'react-native';
import Progress from 'react-native-circular-progress-indicator';
import RNPopover from 'react-native-popover-view';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';

interface Props {
  date: string | null;
  scoreOutOf100: number;
  countdown: string;
  children: React.ReactNode;
  title?: Maybe<string>;
}

const PulseResultWrapper = ({ date, scoreOutOf100, countdown, children, title }: Props) => {
  const navigate = useNavigate();
  const [showInfoMenu, setShowInfoMenu] = useState(false);
  return (
    <Wrapper style={styles.wrapper}>
      <ContentBox style={styles.contentBox} dataSetMedia={ids.contentBox}>
        <TouchableOpacity onPress={() => navigate(-1)}>
          <Text style={styles.backText}>{`< Back`}</Text>
        </TouchableOpacity>
        <View style={styles.headingWrap} dataSet={{ media: ids.headingWrap }}>
          <View style={styles.headingInner} dataSet={{ media: ids.headingInner }}>
            <Progress
              value={scoreOutOf100}
              radius={30}
              clockwise={false}
              inActiveStrokeOpacity={0.4}
              inActiveStrokeColor={getRoadmapProgressColor(scoreOutOf100).color}
              title={`${scoreOutOf100}`}
              titleStyle={styles.progressCircleText}
              activeStrokeColor={getRoadmapProgressColor(scoreOutOf100).fillColor}
              activeStrokeWidth={10}
              inActiveStrokeWidth={10}
              initialValue={scoreOutOf100}
              showProgressValue={false}
            />
            <View style={styles.titleWrap}>
              <Text style={styles.title}>{title || 'Pulse Results'}</Text>
              <Text style={styles.description}>Last check: {date ?? '-'}</Text>
            </View>
          </View>
          <View style={{ justifyContent: 'center' }}>
            <ButtonGeneral label="Priorities" type={ButtonType.user} onPress={() => navigate('/roadmap/priorities')} />
            <View style={styles.infoWrap}>
              <Text style={styles.infoComplete}>{countdown}</Text>
              {Platform.OS === 'web' ? (
                <Tooltip
                  content={
                    <Text style={styles.tooltipText}>Complete all sections to see your suggested content and training to improve your business.</Text>
                  }
                >
                  <TouchableOpacity>
                    <InfoIcon />
                  </TouchableOpacity>
                </Tooltip>
              ) : (
                <RNPopover
                  isVisible={showInfoMenu}
                  onRequestClose={() => setShowInfoMenu(false)}
                  from={
                    <TouchableOpacity onPress={() => setShowInfoMenu(true)}>
                      <InfoIcon />
                    </TouchableOpacity>
                  }
                >
                  <Text style={styles.tooltipText}>Complete all sections to see your suggested content and training to improve your business.</Text>
                </RNPopover>
              )}
            </View>
          </View>
        </View>
        {children}
      </ContentBox>
    </Wrapper>
  );
};

export { PulseResultWrapper };
