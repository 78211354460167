import { PulseResultCategoryBlockPlan } from '@components/roadmap/pulse-result-category-block-plan';
import { Pulse, Recommendation } from '@gql/generated/generated';
import { getRecommendationsAndFeedbackForPulseResult } from '@pages/roadmap/utils';

const getSectionQuestionsFromCurrentPulse = (sectionId: string, currentPulse: Pulse) =>
  currentPulse?.sections?.find((section) => section.id === sectionId)?.questions || [];

type Props = {
  sectionId: string;
  currentPulse: Pulse;
  recommendations: Recommendation[];
};

export const QuestionBlocks = ({ sectionId, currentPulse, recommendations }: Props) => {
  const questions = getSectionQuestionsFromCurrentPulse(sectionId, currentPulse);

  return (
    <>
      {questions.map(({ id, subcategory, answer: { currentScore } }) => {
        const score = currentScore || 0;
        const { trainingRecommendations, contentRecommendations, feedbackForScore } = getRecommendationsAndFeedbackForPulseResult(
          `${sectionId}/${id}`,
          score,
          recommendations
        );

        return (
          <PulseResultCategoryBlockPlan
            key={id}
            sectionId={id}
            title={subcategory ?? ''}
            feedbackTitle={feedbackForScore?.title}
            feedbackDescription={feedbackForScore?.text}
            score={score}
            percent={0}
            contentPieces={contentRecommendations.length || 0}
            trainingCourses={trainingRecommendations.length || 0}
            scoreColor=""
            maxScore={0}
          />
        );
      })}
    </>
  );
};
