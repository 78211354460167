import {
  RoadmapPulse,
  useCreateRoadmapPulseMutation,
  useDeleteRoadmapPulseMutation,
  useGetRoadmapPulseBaseLazyQuery,
  useGetRoadmapPulseLazyQuery,
} from '@gql/generated/generated';
import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

type RoadmapPulseContextType = {
  latestPulse: RoadmapPulse | null;
  selectedPulse: RoadmapPulse | null;
  updateAnswerState: (sectionId: string, questionId: string, answer: string) => void;
  createRoadmapPulse: () => Promise<RoadmapPulse>;
  deleteRoadmapPulse: (roadmapPulseId: string) => Promise<string>;
  selectRoadmapPulse: (roadmapPulseId: string | undefined) => Promise<void>;
  refetchSelectedPulse: () => Promise<void>;
  roadmapPulses: RoadmapPulse[];
  isLoading: boolean;
};

export const RoadmapPulseContext = createContext<RoadmapPulseContextType | null>(null);

export function RoadmapPulseProvider({ children }: { children: ReactNode }) {
  // State
  const [roadmapPulsesBaseState, setRoadmapPulsesBaseState] = useState<RoadmapPulse[]>([]);
  const [latestRoadmapPulseState, setLatestRoadmapPulseState] = useState<RoadmapPulse | null>(null);
  const [selectedRoadmapPulseState, setSelectedRoadmapPulseState] = useState<RoadmapPulse | null>(null);

  // Queries
  const [getRoadmapPulsesBaseQuery, { loading: loadingRoadmapPulseBase }] = useGetRoadmapPulseBaseLazyQuery({ fetchPolicy: 'no-cache' });
  const [getRoadmapPulseQuery, { loading: loadingRoadmapPulse }] = useGetRoadmapPulseLazyQuery({ fetchPolicy: 'no-cache' });

  // Mutations
  const [createRoadmapPulseMutation, { loading: loadingCreateRoadmapPulse }] = useCreateRoadmapPulseMutation();
  const [deleteRoadmapPulseMutation, { loading: loadingDeleteRoadmapPulse }] = useDeleteRoadmapPulseMutation();

  // Memo
  const isLoading = useMemo(
    () => loadingRoadmapPulseBase || loadingRoadmapPulse || loadingDeleteRoadmapPulse || loadingCreateRoadmapPulse,
    [loadingRoadmapPulseBase, loadingRoadmapPulse, loadingDeleteRoadmapPulse, loadingCreateRoadmapPulse]
  );

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      const roadmapPulsesBase = await getRoadmapPulsesBase();
      if (roadmapPulsesBase?.length === 0) {
        // We will recall init on delete so we will reset to empty
        setRoadmapPulsesBaseState([]);
        setLatestRoadmapPulseState(null);
        setSelectedRoadmapPulseState(null);
        return;
      }
      setRoadmapPulsesBaseState(roadmapPulsesBase as RoadmapPulse[]);
      const latestRoadmapPulseId = roadmapPulsesBase?.[0]?.id;
      const latestRoadmapPulse = await getRoadmapPulse(latestRoadmapPulseId!);
      if (!latestRoadmapPulse) {
        throw new Error('Failed to fetch latest roadmap pulse');
      }
      setLatestRoadmapPulseState(latestRoadmapPulse);
      setSelectedRoadmapPulseState(latestRoadmapPulse);
    } catch (err) {
      console.error('ERROR - RoadmapPulseContext - useEffect():', err);
      // TODO: Display error message in UI
    }
  }

  // Methods
  async function refetchRoadmapPulsesBase() {
    try {
      const roadmapPulsesBase = await getRoadmapPulsesBase();
      setRoadmapPulsesBaseState(roadmapPulsesBase);
    } catch (err) {
      console.error('ERROR - RoadmapPulseContext - refetchRoadmapPulsesBase():', err);
      throw err;
    }
  }

  async function getRoadmapPulsesBase() {
    try {
      const roadmapPulsesBase = await getRoadmapPulsesBaseQuery();
      return roadmapPulsesBase.data?.getRoadmapPulse as RoadmapPulse[];
    } catch (err) {
      console.error('ERROR - RoadmapPulseContext - getRoadmapPulsesBase():', err);
      throw err;
    }
  }

  async function getRoadmapPulse(roadmapPulseId: string) {
    try {
      const roadmapPulse = await getRoadmapPulseQuery({
        variables: {
          roadmapPulseId,
        },
      });
      return roadmapPulse.data?.getRoadmapPulse[0] as RoadmapPulse;
    } catch (err) {
      console.error('ERROR - RoadmapPulseContext - getRoadmapPulse():', err);
      throw err;
    }
  }

  async function createRoadmapPulse(): Promise<RoadmapPulse> {
    try {
      const { data } = await createRoadmapPulseMutation();
      const roadmapPulse = data?.createRoadmapPulse;
      if (!roadmapPulse) throw new Error('Failed to create roadmap pulse');
      await refetchRoadmapPulsesBase();
      setSelectedRoadmapPulseState(roadmapPulse);
      if (latestRoadmapPulseState === null) {
        setLatestRoadmapPulseState(roadmapPulse);
      }
      return roadmapPulse;
    } catch (err) {
      console.error('ERROR - createRoadmapPulse:', err);
      throw err;
    }
  }

  async function deleteRoadmapPulse(roadmapPulseId: string): Promise<string> {
    if (!roadmapPulseId) throw new Error('Provided roadmap pulse id is invalid');
    try {
      const { data } = await deleteRoadmapPulseMutation({
        variables: { deleteRoadmapPulseId: roadmapPulseId },
      });
      const deletedRoadmapPulseId = data?.deleteRoadmapPulse;
      if (!deletedRoadmapPulseId) throw new Error('Failed to delete roadmap pulse');
      await init();
      return deletedRoadmapPulseId;
    } catch (err) {
      console.error('ERROR - deleteRoadmapPulse:', err);
      throw err;
    }
  }

  async function selectRoadmapPulse(roadmapPulseId: string | undefined) {
    try {
      if (!roadmapPulseId) throw new Error('Provided roadmap pulse id is invalid');
      if (roadmapPulseId === selectedRoadmapPulseState?.id) return;
      const roadmapPulse = await getRoadmapPulse(roadmapPulseId);
      setSelectedRoadmapPulseState(roadmapPulse);
    } catch (err) {
      console.error('ERROR - RoadmapPulseContext - selectRoadmapPulse():', err);
      throw err;
    }
  }

  async function refetchSelectedPulse() {
    try {
      if (!selectedRoadmapPulseState?.id) throw new Error('You cannot refetch the selected pulse, there is not one');
      const roadmapPulse = await getRoadmapPulse(selectedRoadmapPulseState.id);
      setSelectedRoadmapPulseState(roadmapPulse);
    } catch (err) {
      console.error('ERROR - RoadmapPulseContext - selectRoadmapPulse():', err);
      throw err;
    }
  }

  function updateAnswerState(sectionId: string, questionId: string, answer: string) {
    // Check if there is a selected roadmap pulse
    if (!selectedRoadmapPulseState) {
      throw new Error('There is no roadmap pulse state to update');
    }
    // Check the answer is a string
    if (typeof answer !== 'string') {
      throw new Error('Provided answer must be a string');
    }
    // Check if there is a section with the provided id
    const section = selectedRoadmapPulseState.sections.find((s) => s.id === sectionId);
    if (!section) {
      throw new Error('Provided section id was not found in the selected roadmap pulse');
    }
    // Check if there is a question with the provided id
    const question = section.questions.find((q) => q.id === questionId);
    if (!question) {
      throw new Error('Provided question id was not found in the selected roadmap pulse');
    }
    setSelectedRoadmapPulseState((prevState) => {
      if (!prevState) return prevState;
      return {
        ...prevState,
        sections: prevState?.sections.map((s) => {
          if (s.id === sectionId) {
            return {
              ...s,
              questions: s.questions.map((q) => {
                if (q.id === questionId) {
                  return {
                    ...q,
                    answer: {
                      value: answer,
                    },
                  };
                }
                return q;
              }),
            };
          }
          return s;
        }),
      };
    });
  }

  return (
    <RoadmapPulseContext.Provider
      value={{
        latestPulse: latestRoadmapPulseState,
        selectedPulse: selectedRoadmapPulseState,
        roadmapPulses: roadmapPulsesBaseState,
        updateAnswerState,
        createRoadmapPulse,
        deleteRoadmapPulse,
        selectRoadmapPulse,
        refetchSelectedPulse,
        isLoading,
      }}
    >
      {children}
    </RoadmapPulseContext.Provider>
  );
}
