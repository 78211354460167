import { ButtonGeneral, ButtonType, ButtonVariant } from '@components/general/button-general';
import { PlayIcon } from '@components/general/icons/org-icons';
import { ContentBox } from '@components/general/layouts/content-box';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { RoadmapBlock } from '@components/roadmap/roadmap-block';
import { RoadmapTutorial } from '@components/roadmap/roadmap-tutorial';
import { RoadmapPulseContext } from '@context/RoadmapPulseContext';
import { useUserContext } from '@context/UserContext';
import { getDownloadURL, ref } from '@firebase/storage';
import { useEmployerGetRoadmapQuery, useUpdateRoadmapMutation } from '@gql/generated/generated';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { FEATURE_FLAGS } from '@utils/featureFlags';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ActivityIndicator, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';
import { storage } from '../../../firebase';
import { getCurrentRoadmapItemsFromData, getQuestionAndAnswerCount } from '../utils';

const RoadmapIntro = () => {
  const navigate = useNavigate();
  const [tutorialModal, setTutorialModal] = useState<{ videoUri?: string; title: string; subtitle: string } | null>(null);
  const roadmapPulseContext = useContext(RoadmapPulseContext);
  if (!roadmapPulseContext) throw new Error('You have not setup the provider');
  const { latestPulse } = roadmapPulseContext;
  const isRoadmapPulseEnabled = useFeatureFlag(FEATURE_FLAGS.ROADMAP_PULSE);
  const isRoadmapGoalEnabled = useFeatureFlag(FEATURE_FLAGS.ROADMAP_GOAL);
  const isRoadmapNorthStarEnabled = useFeatureFlag(FEATURE_FLAGS.ROADMAP_NORTH_STAR);
  const isRoadmapPrioritiesEnabled = useFeatureFlag(FEATURE_FLAGS.ROADMAP_PRIORITIES);

  const { loadingCurrentUser } = useUserContext();

  const [updateViewedByOrg] = useUpdateRoadmapMutation({
    variables: {
      input: {
        viewedByOrganisation: true,
      },
    },
  });

  const { data, loading } = useEmployerGetRoadmapQuery({
    onCompleted: (roadmap) => {
      if (!roadmap?.employerGetRoadmap.viewedByOrganisation) {
        roadmapTutorialModal.videoUri && setTutorialModal(roadmapTutorialModal);
        updateViewedByOrg();
      }
    },
  });

  const [roadmapVideos, setRoadmapVideos] = useState<{ goal: string; northStar: string; pulse: string; tutorial: string } | null>(null);
  const getRoadmapVideos = useCallback(async () => {
    const videoPaths = {
      goal: 'roadmap/roadmap-intro/Goal-InfoGraphicVideo.mp4',
      northStar: '/roadmap/roadmap-intro/NorthStar-InfoGraphic.mp4',
      pulse: 'roadmap/roadmap-intro/Pulse-RoadMap.mp4',
      tutorial: 'roadmap/roadmap-intro/RoadMap-tutorial.mp4',
    };

    const uris = await Promise.all(Object.values(videoPaths).map((path) => getDownloadURL(ref(storage, path))));

    const videoUris = Object.keys(videoPaths).reduce((accumulator, key, index) => {
      return { ...accumulator, [key]: uris[index] };
    }, {} as typeof videoPaths);

    setRoadmapVideos({ ...videoUris });
  }, []);

  useEffect(() => {
    getRoadmapVideos();
  }, [getRoadmapVideos]);

  const renderLoading = () => (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ActivityIndicator size="large" color="#213470" style={{ paddingVertical: 20 }} />
    </View>
  );

  if (!data || loading || loadingCurrentUser || !roadmapVideos) {
    return renderLoading();
  }

  const getRoadmapBlocks = () => {
    const sections = data?.employerGetRoadmap && getCurrentRoadmapItemsFromData(data?.employerGetRoadmap);
    const northStarSections = sections?.northStarSections ?? [];
    const pulseSections = latestPulse?.sections ?? [];
    const goalSections = sections?.goalSections ?? [];

    const northStarSectionsData = data && getQuestionAndAnswerCount(northStarSections);
    const goalSectionsData = data && getQuestionAndAnswerCount(goalSections);
    const pulseSectionsData = data && getQuestionAndAnswerCount(pulseSections);

    const response = [
      {
        title: 'North Star',
        subtitle: 'The 3 Year Plan',
        sections: northStarSections,
        sectionsData: northStarSectionsData,
        minuteTotal: 30,
        onPlayNavigate: 'north-star',
        videoUri: roadmapVideos.northStar,
        isDisabled: !isRoadmapNorthStarEnabled,
      },
      {
        title: 'Goal',
        subtitle: 'The Annual Plan',
        sections: goalSections,
        sectionsData: goalSectionsData,
        minuteTotal: 15,
        onPlayNavigate: 'goal',
        videoUri: roadmapVideos.goal,
        isDisabled: !isRoadmapGoalEnabled,
      },
      {
        title: 'Pulse',
        subtitle: '90-day vitality check',
        sections: pulseSections,
        sectionsData: pulseSectionsData,
        minuteTotal: 90,
        onPlayNavigate: 'pulse',
        videoUri: roadmapVideos.pulse,
        isDisabled: !isRoadmapPulseEnabled,
      },
    ];

    return response;
  };

  const roadmapTutorialModal = {
    videoUri: roadmapVideos.tutorial,
    title: 'Roadmap',
    subtitle: 'The journey to business success and exploration',
  };

  return (
    <Wrapper style={styles.wrapper} dataSetMedia={ids.wrapper}>
      <ContentBox style={styles.contentBox} dataSetMedia={ids.contentBox}>
        <View style={styles.titleWrap}>
          <Text style={styles.title}>Roadmap</Text>
          <Text style={styles.description}>
            Answer insightful questions, set strategic goals, and effortlessly monitor your progress, all in our simply but intuitive platform.
            Elevate your business to new heights with your personal roadmap for success.
          </Text>
        </View>
        {isRoadmapPrioritiesEnabled && (
          <TouchableOpacity style={styles.priorities} onPress={() => navigate('/roadmap/priorities')}>
            <View>
              <Text style={styles.prioritiesTitle}>Priorities</Text>
              <Text style={styles.prioritiesSubtitle}>Manage your business's current priorities</Text>
            </View>
            <View style={styles.prioritiesIcon}>
              <PlayIcon color="white" />
            </View>
          </TouchableOpacity>
        )}
        <View
          style={[styles.blockWrap, { justifyContent: getRoadmapBlocks().length > 1 ? 'space-evenly' : 'center' }]}
          dataSet={{ media: ids.blockWrap }}
        >
          {data && roadmapVideos && (
            <>
              {getRoadmapBlocks().map(({ title, isDisabled, subtitle, sections, sectionsData, minuteTotal, onPlayNavigate, videoUri }) => (
                <RoadmapBlock
                  key={videoUri}
                  title={title}
                  subtitle={subtitle}
                  isDisabled={isDisabled}
                  sectionsTotal={sections.length}
                  questionsTotal={sectionsData?.questionCount ?? 0}
                  minuteTotal={minuteTotal}
                  totalQuestions={sectionsData?.questionCount ?? 0}
                  totalAnsweredQuestions={sectionsData?.answerCount ?? 0}
                  onPlay={() => navigate(onPlayNavigate)}
                  onPressInfo={() => videoUri && setTutorialModal({ videoUri, title, subtitle })}
                />
              ))}
            </>
          )}
        </View>
        {roadmapVideos.tutorial && (
          <ButtonGeneral
            label="Watch tutorial video"
            type={ButtonType.user}
            variant={ButtonVariant.Secondary}
            onPress={() => roadmapTutorialModal.videoUri && setTutorialModal(roadmapTutorialModal)}
          />
        )}
      </ContentBox>
      {tutorialModal && tutorialModal.videoUri && (
        <RoadmapTutorial
          isVisible={!!tutorialModal}
          onClose={() => setTutorialModal(null)}
          title={`Welcome to ${tutorialModal.title}`}
          subtitle={tutorialModal.subtitle}
          videoUri={tutorialModal.videoUri}
        />
      )}
    </Wrapper>
  );
};

export { RoadmapIntro };
