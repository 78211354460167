import humanizeDuration from 'humanize-duration';

export const convertSecondsToDisplay = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  let str = '';

  if (hours > 0) {
    if (hours === 1) {
      str = str + `${hours} hour`;
    } else {
      str = str + `${hours} hours`;
    }
  }

  if (minutes > 0) {
    if (minutes === 1) {
      str = str + ` ${minutes} min`;
    } else {
      str = str + ` ${minutes} mins`;
    }
  }

  if (secs > 0) {
    if (secs === 1) {
      str = str + ` ${secs} sec`;
    } else {
      str = str + ` ${secs} secs`;
    }
  }

  return str.trim();
};

export const getDifferenceInDays = (t1: number, t2: number) => {
  const differenceInMs = Math.abs(t1 - t2);
  return differenceInMs / (1000 * 60 * 60 * 24);
};

export function getHumanReadableCooldown(timestamp: number, cooldownDays: number = 7): string {
  const created = new Date(timestamp);
  const cooldownEndDate = new Date(created.getTime() + cooldownDays * 24 * 60 * 60 * 1000);
  const currentDate = new Date();
  const timeDifference = cooldownEndDate.getTime() - currentDate.getTime();

  // Ensure that no negative time is returned (in case cooldown has already passed)
  const remainingMillis = Math.max(timeDifference, 0);

  // Use humanize-duration to convert the remaining time to a human-readable format
  return humanizeDuration(remainingMillis, { largest: 3, round: true, units: ['d', 'h', 'm'] });
}
