import { ModalContainer } from '@components/org/saved/modals/ModalContainer';
import { ConfirmationModalContent } from '@components/org/saved/modals/confirmation-modal/ConfirmationModalContent';
import { GenericModalLayout } from '@components/org/saved/modals/generic-modal-layout/GenericModalLayout';
import { useUserContext } from '@context/UserContext';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { UpdateContext } from '@utils/UpdateContext';
import { FEATURE_FLAGS } from '@utils/featureFlags';
import { signOut } from 'firebase/auth';
import React, { useContext, useState } from 'react';
import { Modal, Platform, Text, TouchableOpacity, View } from 'react-native';
import { Location, useLocation, useNavigate } from 'react-router-native';

import { styles } from './style';
import { auth } from '../../../firebase';
import {
  AnalyticsIcon,
  BarGraphIcon,
  CalendarIcon,
  CogIcon,
  ContentIcon,
  DrawingPinIcon,
  HelpIcon,
  KeyIcon,
  LabelIcon,
  LogOutIcon,
  MortarboardHatIcon,
  UserAvatarIcon,
} from '../../general/icons';
import { InitialsCircle } from '../../general/initials-circle';
import { Row } from '../../general/row';

let Link: any;

Platform.select({
  native: () => (Link = <></>),
  default: () => (Link = require('react-router-dom').Link),
})();

interface SideBarItemProps {
  hasUpdated: any;
  location: Location;
  to: string;
  label: string;
  lightIcon: React.ReactNode;
  darkIcon: React.ReactNode;
  subLabels?: string[];
}

const SIDE_BAR_ITEMS = [
  {
    label: 'Analytics',
    to: 'Analytics',
    lightIcon: <AnalyticsIcon />,
    darkIcon: <AnalyticsIcon color="#0D1738" />,
  },
  {
    label: 'Content',
    to: 'Content',
    lightIcon: <ContentIcon />,
    darkIcon: <ContentIcon color="#0D1738" />,
  },
  {
    label: 'Events',
    to: 'Events',
    lightIcon: <CalendarIcon color="#ffffff" />,
    darkIcon: <CalendarIcon color="#0D1738" />,
  },
  {
    label: 'Training',
    to: 'Training',
    lightIcon: <MortarboardHatIcon />,
    darkIcon: <MortarboardHatIcon color="#0D1738" />,
  },
  {
    label: 'Posts',
    to: 'Posts',
    lightIcon: <DrawingPinIcon />,
    darkIcon: <DrawingPinIcon color="#0D1738" />,
  },
  {
    label: 'Polls',
    to: 'Polls',
    lightIcon: <BarGraphIcon />,
    darkIcon: <BarGraphIcon color="#0D1738" />,
  },
  {
    label: 'Partners',
    to: 'Marketplace',
    lightIcon: <LabelIcon />,
    darkIcon: <LabelIcon color="#0D1738" />,
  },
  {
    label: 'Users',
    to: 'Users',
    lightIcon: <UserAvatarIcon />,
    darkIcon: <UserAvatarIcon color="#0D1738" />,
  },
  {
    label: 'Roadmap',
    to: 'Roadmap',
    lightIcon: <KeyIcon />,
    darkIcon: <KeyIcon color="#0D1738" />,
    subLabels: ['Score Recommendations'],
  },
];

const SideBarItem = ({ hasUpdated, location, label, to, lightIcon, darkIcon, subLabels }: SideBarItemProps) => {
  const scoreAssnFeatureFlag = useFeatureFlag(FEATURE_FLAGS.ROADMAP_PULSE_SCORE_ASSNS);

  const isSelected =
    location.pathname === `/${to}` ||
    (location.state && location.state.title && location.state.title.includes(to)) ||
    location.pathname.includes(to) ||
    (to === 'Users' && location.pathname === '/user_editor');

  return (
    <>
      <Link onClick={() => hasUpdated.update(0)} style={isSelected ? styles.menuBtnSelected : styles.menuBtn} to={`/${to}`}>
        <Row style={{ paddingLeft: 24 }}>
          <View style={{ display: 'flex', justifyContent: 'center' }}>{isSelected ? darkIcon : lightIcon}</View>
          <Text style={[isSelected ? styles.btnTextBlue : styles.btnTextWhite, { marginLeft: 8, display: 'flex', alignItems: 'center' }]}>
            {label}
          </Text>
        </Row>
      </Link>
      {isSelected &&
        subLabels &&
        subLabels.length > 0 &&
        subLabels
          .filter((s) => (s === 'Score Recommendations' && scoreAssnFeatureFlag) || s !== 'Score Recommendations')
          .map((subLabel) => (
            <Link
              key={subLabel}
              onClick={() => hasUpdated.update(0)}
              style={isSelected ? styles.subMenuBtnSelected : styles.menuBtn}
              to={`/${to}/${subLabel.toLowerCase().replace(' ', '-')}`}
            >
              <Row style={{ paddingLeft: 40 }}>
                <View style={{ display: 'flex', justifyContent: 'center' }} />
                <Text style={[isSelected ? styles.btnTextBlue : styles.btnTextWhite, { marginLeft: 8, display: 'flex', alignItems: 'center' }]}>
                  {subLabel}
                </Text>
              </Row>
            </Link>
          ))}
    </>
  );
};

const SideBar = ({ setFromEditor, setLogout }: any) => {
  const [linkFocus, setLinkFocus] = useState(-1);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { currentUser } = useUserContext();

  const navigation = useNavigate();
  const SignOut = () => {
    signOut(auth)
      .then(() => {
        setLogout(true);
        navigation('/login');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const location = useLocation();

  const hasUpdated = useContext(UpdateContext);

  const handleEditorLaunch = (location: string) => setFromEditor(location);

  const tabs = ['/', '/content', '/events', '/trainings', '/posts', '/polls', '/marketplace', '/users', '/roadmap', '/settings', '/help'];

  return (
    <>
      <Modal visible={showLogoutModal} transparent>
        <ModalContainer>
          <GenericModalLayout
            title="Log out"
            closeModal={() => setShowLogoutModal(false)}
            hasButtonLayout
            buttonName="Log out"
            onActionButtonPress={() => SignOut()}
            withCancel
          >
            <ConfirmationModalContent mainText="You are attempting to log out of TRN site. Do you really want to leave the site?" secondaryText="" />
          </GenericModalLayout>
        </ModalContainer>
      </Modal>
      <View style={[styles.mainContainer]}>
        <View>
          {SIDE_BAR_ITEMS.map((item, index) => {
            return <SideBarItem key={index} location={location} hasUpdated={hasUpdated} {...item} />;
          })}
        </View>
        <View style={styles.footer}>
          <Row style={{ alignItems: 'center' }}>
            <InitialsCircle
              initials={
                currentUser?.accountInfo?.firstName[0] && currentUser?.accountInfo?.lastName[0]
                  ? currentUser?.accountInfo?.firstName[0] + currentUser?.accountInfo?.lastName[0]
                  : ''
              }
              backgroundColor="#FFC96B"
            />
            <View style={{ display: 'flex', marginLeft: 6, flex: 1 }}>
              <Text style={styles.name}>{currentUser ? currentUser?.accountInfo?.firstName + ' ' + currentUser?.accountInfo?.lastName : ''}</Text>
              <Text style={styles.role}>Admin</Text>
            </View>
          </Row>
          <Link onClick={() => hasUpdated.update(0)} to="/settings" style={styles.option}>
            <Row>
              <CogIcon />
              <Text style={styles.optionText}>Settings</Text>
            </Row>
          </Link>
          <Link onClick={() => hasUpdated.update(0)} to={tabs[10]} style={styles.option}>
            <HelpIcon />
            <Text style={styles.optionText}>Help</Text>
          </Link>
          <TouchableOpacity onPress={() => setShowLogoutModal(true)} style={styles.option}>
            <LogOutIcon />
            <Text style={styles.optionText}>Log out</Text>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

export { SideBar };
