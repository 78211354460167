import { getRoadmapColorFromScoreColor } from '@utils/misc';
import React from 'react';
import { Text, View } from 'react-native';

import { ids, styles } from './style';

interface Props {
  score: number;
  scoreColor: string;
  maxScore: number;
}

const PulseScoreCircle = ({ score, scoreColor, maxScore }: Props) => (
  <View style={[styles.circle, { backgroundColor: getRoadmapColorFromScoreColor(scoreColor).fillColor }]} dataSet={{ media: ids.circle }}>
    {!!maxScore && (
      <>
        <Text style={styles.score} dataSet={{ media: ids.score }}>
          {score}
          <Text style={styles.scoreOutOfText} dataSet={{ media: ids.scoreOutOfText }}>
            /{maxScore}
          </Text>
        </Text>
        <Text style={styles.scoreText}>Score</Text>
      </>
    )}
  </View>
);

export { PulseScoreCircle };
