import { ContentBox } from '@components/general/layouts/content-box';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import React, { useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';

interface Props {
  title: string;
  percent: number;
  children: React.ReactNode;
  subcategoryDropdown?: JSX.Element;
  feedbackText?: string;
  feedbackTitle?: string;
}

const PulseContentWrapper = ({ title, percent, children, subcategoryDropdown, feedbackText, feedbackTitle }: Props) => {
  const navigate = useNavigate();
  const [showInfoMenu, setShowInfoMenu] = useState(false);
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);

  return (
    <Wrapper style={styles.wrapper}>
      <ContentBox style={styles.contentBox} dataSetMedia={ids.contentBox}>
        <TouchableOpacity onPress={() => navigate(`/roadmap/pulse/result`)}>
          <Text style={styles.backText}>{`< Back to Pulse`}</Text>
        </TouchableOpacity>
        <View style={styles.headingWrap}>
          <View>
            <Text style={styles.title}>{title}</Text>
            {subcategoryDropdown}
            {/* {feedbackText ? (
              <>
                <Row>
                  <HtmlParser htmlString={feedbackText} isTruncated />
                  <TouchableOpacity
                    onPress={() => setIsFeedbackModalVisible(true)}
                  >
                    <Text
                      style={{ color: "rgb(44, 110, 203)", lineHeight: 20 }}
                    >
                      {" "}
                      read more
                    </Text>
                  </TouchableOpacity>
                </Row>
                <CompleteModal
                  isVisible={isFeedbackModalVisible}
                  onClose={() => setIsFeedbackModalVisible(false)}
                  title={title}
                  htmlString={feedbackText}
                />
              </>
            ) : null} */}
          </View>
          <View style={styles.infoWrap}>
            <Text style={styles.infoPercentage}>{Math.floor(percent)}%</Text>
            <Text style={styles.infoComplete}>Complete</Text>
          </View>
        </View>
        {children}
      </ContentBox>
    </Wrapper>
  );
};

export { PulseContentWrapper };
