import { SortBy, TrainingSortBy, UserType } from '@gql/generated/generated';
import { useReducer } from 'react';

export enum FilterAction {
  SET_CATEGORY,
  SET_JOB_ROLE,
  SET_LENGTH,
  SET_MEMBER_TYPE,
  SET_POPULARITY,
  SET_CONTENT_TYPE,
  SET_DATE,
  RESET,
  SET_EVENT_TYPE,
  SET_DURATION,
  SET_SORT_BY,
}

interface Action {
  type: FilterAction;
  payload: any;
}

export interface FiltersState {
  category: string[];
  popularity: number[];
  memberType: UserType[];
  jobRole: string[];
  length: string[];
  contentType: string[];
  date: { end: number | null; start: number | null };
  eventType: string[];
  sortBy: SortBy | TrainingSortBy | null;
}

const initialFilters = {
  category: [],
  popularity: [],
  memberType: [],
  jobRole: [],
  length: [],
  contentType: [],
  date: { end: null, start: null },
  eventType: [],
  duration: [],
  sortBy: null,
};

const reducer = (state: FiltersState, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case FilterAction.SET_CATEGORY:
      return {
        ...state,
        category: payload,
      };
    case FilterAction.SET_MEMBER_TYPE:
      return {
        ...state,
        memberType: payload,
      };
    case FilterAction.SET_JOB_ROLE:
      return {
        ...state,
        jobRole: payload,
      };
    case FilterAction.SET_POPULARITY:
      if (payload.length && state.popularity.includes(payload[0])) {
        return {
          ...state,
          popularity: [],
        };
      }
      return {
        ...state,
        popularity: payload.map((num: string) => Number(num)),
      };
    case FilterAction.SET_LENGTH:
      return {
        ...state,
        length: payload,
      };
    case FilterAction.SET_CONTENT_TYPE:
      return {
        ...state,
        contentType: payload,
      };
    case FilterAction.SET_DATE:
      return {
        ...state,
        date: payload,
      };
    case FilterAction.SET_EVENT_TYPE:
      return {
        ...state,
        eventType: payload,
      };
    case FilterAction.SET_DURATION:
      return {
        ...state,
        duration: payload,
      };
    case FilterAction.SET_SORT_BY:
      return {
        ...state,
        sortBy: payload[0],
      };
    case FilterAction.RESET:
      return initialFilters;
    default:
      return state;
  }
};

const useFilters = (initialSortBy: SortBy | TrainingSortBy): [FiltersState, React.Dispatch<Action>] => {
  const [filters, dispatch] = useReducer(reducer, {
    ...initialFilters,
    sortBy: initialSortBy,
  });

  return [filters, dispatch];
};

export { useFilters };
