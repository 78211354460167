import { FeaturedEvents } from '@components/events/featured-events';
import { ButtonGeneral, ButtonVariant } from '@components/general/button-general';
import { Col } from '@components/general/col';
import { Filters } from '@components/general/filters';
import { LeftArrow, RightArrow } from '@components/general/icons';
import { IndexPageCard } from '@components/general/index-page-card';
import { ContentBox } from '@components/general/layouts/content-box';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { SortByTabs } from '@components/general/sort-by-tabs';
import { Toast } from '@components/general/toast';
import { FiltersState } from '@hooks/useFilters';
import { Frame, HorizontalGrid } from '@shopify/polaris';
import { PageVariant } from '@utils/models';
import React, { useEffect, useMemo } from 'react';
import { ActivityIndicator, Text, View } from 'react-native';
import { useLocation, useNavigate } from 'react-router-native';

import { ids, styles } from './style';
import { useGetCardContent } from './useGetCardContent';

interface Props {
  variant: PageVariant.content | PageVariant.event | PageVariant.training;
}

export const IndexPageLayout = ({ variant }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data,
    setPageSelected,
    pageSelected,
    toastMessage,
    hasNextPage,
    sortedHighlightedContent,
    filters,
    setFilters,
    loading,
    trainingLoading,
    contentLoading,
    title,
    setToastMessage,
  } = useGetCardContent({ variant });

  useEffect(() => {
    if (location.state === 'ForbiddenError') {
      setToastMessage('No access');
    }
  }, [location.state, setToastMessage]);

  const cards = useMemo(() => {
    if (!data?.length) return null;
    return data.map((item) => {
      switch (item.__typename) {
        case 'Event':
          return (
            <IndexPageCard
              key={item.id}
              {...item}
              categories={item.categories ?? []}
              description={item.summary ?? ''}
              id={item.id}
              date={item.dateFrom}
              onPress={() =>
                navigate(`/events/${item.id}`, {
                  state: {
                    id: item.id,
                    categories: item.categories,
                  },
                })
              }
              variant={variant}
            />
          );
        case 'Training':
          return (
            <IndexPageCard
              {...item}
              key={item.id}
              id={item.id}
              description={item.shortDescription}
              categories={item.categories ?? []}
              speakers={item.instructors}
              onPress={() => navigate(`/training/${item.id}`)}
              variant={variant}
              lessons={item.numLessons ?? 0}
            />
          );
        case 'Content':
          return (
            <IndexPageCard
              key={item.id}
              {...item}
              categories={item.categories ?? []}
              description={item.summary ?? ''}
              id={item.id}
              onPress={() =>
                navigate(`/content/${item.id}`, {
                  state: {
                    id: item.id,
                    categories: item.categories,
                  },
                })
              }
              variant={variant}
            />
          );
        default:
          return null;
      }
    });
  }, [data, navigate, variant]);

  return (
    <Frame>
      <Wrapper style={styles.wrapper} dataSetMedia={ids.wrapper}>
        <Toast text={toastMessage} active={!!toastMessage} onDismiss={() => setToastMessage('')} duration={4500} error />
        <ContentBox style={styles.contentBox} dataSetMedia={ids.contentBox}>
          <View style={styles.titleWrap} dataSet={{ media: ids.titleWrap }}>
            <Text style={styles.title}>{title}</Text>
            {sortedHighlightedContent?.length ? (
              <FeaturedEvents data={sortedHighlightedContent} isTraining={variant === PageVariant.training} />
            ) : null}
          </View>
          <SortByTabs variant={variant} setFilters={setFilters} />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              marginBottom: 20,
              zIndex: 9,
              width: '100%',
              gap: '10px' as any,
            }}
          >
            <View style={styles.filterRow} dataSet={{ media: ids.filterRow }}>
              <Filters
                filters={filters}
                setFilters={(f: any) => {
                  setPageSelected(1);
                  setFilters(f);
                }}
                isTraining={variant === PageVariant.training}
                isEvent={variant === PageVariant.event}
                isContent={variant === PageVariant.content}
              />
            </View>
            <View style={styles.paginationRow} dataSet={{ media: ids.paginationRow }}>
              <ButtonGeneral
                style={styles.paginationButtonLeft}
                variant={ButtonVariant.Secondary}
                onPress={() => setPageSelected(pageSelected - 1)}
                disabled={pageSelected === 1}
              >
                <LeftArrow />
              </ButtonGeneral>
              <ButtonGeneral
                style={styles.paginationButtonRight}
                variant={ButtonVariant.Secondary}
                disabled={!hasNextPage}
                onPress={() => setPageSelected(pageSelected + 1)}
              >
                <RightArrow />
              </ButtonGeneral>
            </View>
          </View>
          <View dataSet={{ media: ids.eventsContainer }}>
            {loading || trainingLoading || contentLoading ? (
              <Col style={styles.loadingContainer}>
                <ActivityIndicator size="large" color="#202223" />
              </Col>
            ) : (
              <HorizontalGrid gap="5" columns={{ xs: 1, sm: 1, md: 2, lg: 3 }}>
                {cards}
              </HorizontalGrid>
            )}
          </View>
          <View style={[styles.paginationRow, { marginTop: 15, marginLeft: 'auto', marginRight: 0 }]} dataSet={{ media: ids.paginationRow }}>
            <ButtonGeneral
              style={styles.paginationButtonLeft}
              variant={ButtonVariant.Secondary}
              onPress={() => setPageSelected(pageSelected - 1)}
              disabled={pageSelected === 1}
            >
              <LeftArrow />
            </ButtonGeneral>
            <ButtonGeneral
              style={styles.paginationButtonRight}
              variant={ButtonVariant.Secondary}
              disabled={!hasNextPage}
              onPress={() => setPageSelected(pageSelected + 1)}
            >
              <RightArrow />
            </ButtonGeneral>
          </View>
        </ContentBox>
      </Wrapper>
    </Frame>
  );
};
