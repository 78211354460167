import { ActivityIndicator, StyleProp, View, ViewStyle } from 'react-native';

export function ActivityIndicatorTRN({
  style = {},
  viewStyle = {},
  size = 'large',
  color = '#213470',
}: {
  size?: 'small' | 'large';
  color?: string;
  style?: StyleProp<ViewStyle>;
  viewStyle?: StyleProp<ViewStyle>;
}) {
  return (
    <View style={viewStyle}>
      <ActivityIndicator style={[style]} size={size} color={color} />
    </View>
  );
}
