import { useUserContext } from '@context/UserContext';
import { FEATURE_FLAGS, FeatureFlagKey } from '@utils/featureFlags';
import { usePostHog } from 'posthog-js/react';

export function useFeatureFlag(featureFlag: FeatureFlagKey) {
  const posthog = usePostHog();
  const user = useUserContext();
  const isEmployer = user?.currentUser?.role?.isEmployer;

  switch (featureFlag) {
    case FEATURE_FLAGS.TRAINING_PROGRESS: {
      if (!isEmployer || !posthog) {
        return false;
      }
      return posthog.isFeatureEnabled(featureFlag);
    }
    default: {
      return posthog.isFeatureEnabled(featureFlag);
    }
  }
}
