import {
  RoadmapPulseScoreAssnSubCategoryLevelContentType,
  RoadmapPulseScoreAssnCategory,
  RoadmapPulseScoreAssnSubCategory,
  useUpdateRoadmapPulseScoreAssnsSubCategoryLevelMutation,
  RoadmapPulseScoreAssnSubCategoryLevel,
  useGetRoadmapPulseScoreAssnsLazyQuery,
  RoadmapPulseScoreAssnSubCategoryLevelInput,
} from '@gql/generated/generated';
import { useGetObjectsAlgolia } from '@hooks/useGetObjectsAlgolia';
import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-native';

type ScoreAssociationsContextType = {
  roadmapPulseScoreAssns: RoadmapPulseScoreAssnCategory[];
  getRoadmapPulseScoreAssnsLoading: boolean;
  updateScoreAssnsLevelLoading: boolean;
  selectedScoreAssnLevel?: RoadmapPulseScoreAssnSubCategoryLevel | null;
  selectedCategoryName: string | null;
  selectedSubcategoryName: string | null;
  isValidParams: boolean;
  assignedContentMetadataLoading: boolean;
  assignedContentMetadata: any[];
  assignedTrainingMetadata: any[];
  assignedTrainingMetadataLoading: boolean;
  selectedScoreAssnSubcategory?: RoadmapPulseScoreAssnSubCategory | null;
  setSubCategoryName: (scoreAssns: RoadmapPulseScoreAssnCategory[] | null, categoryName: string, subcategoryName: string) => void;
  isLevelValid: (level: string) => boolean;
  isSubcategoryValid: (categoryName: string, subcategoryName: string) => boolean;
  isCategoryValid: (categoryName: string) => boolean;
  getDefaultCategory: () => RoadmapPulseScoreAssnCategory | null;
  getDefaultSubcategory: (scoreAssns: RoadmapPulseScoreAssnCategory[] | null, categoryName: string) => RoadmapPulseScoreAssnSubCategory | null;
  unassignScoreAssociationContent: (params: RoadmapPulseScoreAssnSubCategoryLevelInput) => Promise<void>;
  assignScoreAssociationContent: (contents: RoadmapPulseScoreAssnSubCategoryLevelInput) => Promise<void>;
  updateScoreAssociationFeedback: (params: RoadmapPulseScoreAssnSubCategoryLevelInput) => Promise<void>;
};

export const ScoreAssociationsContext = createContext<ScoreAssociationsContextType | null>(null);

export function ScoreAssociationsProvider({ children }: { children?: ReactNode }) {
  // State
  const [isValidParams, setIsValidParams] = useState<boolean>(false);
  const [selectedCategoryName, setSelectedCategoryName] = useState<string | null>(null);
  const [selectedSubcategoryName, setSelectedSubcategoryName] = useState<string | null>(null);
  const [roadmapPulseScoreAssnState, setRoadmapPulseScoreAssnState] = useState<RoadmapPulseScoreAssnCategory[]>([]);

  // Params
  const { categoryId, subcategoryId, score } = useParams();

  const [getRoadmapPulseScoreAssnsQuery, { loading: getRoadmapPulseScoreAssnsLoading }] = useGetRoadmapPulseScoreAssnsLazyQuery();
  const [updateScoreAssnsLevel, { loading: updateScoreAssnsLevelLoading }] = useUpdateRoadmapPulseScoreAssnsSubCategoryLevelMutation();

  const selectedScoreAssnSubcategory = useMemo(() => {
    if (selectedCategoryName && selectedSubcategoryName && roadmapPulseScoreAssnState.length) {
      const category = roadmapPulseScoreAssnState.find((c) => c.name === selectedCategoryName);
      const subcategory = category?.subCategories.find((s) => s.name === selectedSubcategoryName);
      return subcategory;
    }
    return null;
  }, [selectedCategoryName, selectedSubcategoryName, roadmapPulseScoreAssnState.length]);

  const selectedScoreAssnLevel = useMemo(() => {
    if (roadmapPulseScoreAssnState.length && !updateScoreAssnsLevelLoading) {
      const category = roadmapPulseScoreAssnState.find((c) => c.name === categoryId);
      if (!category) return null;
      const subcategory = category.subCategories.find((s) => s.name === subcategoryId);
      if (!subcategory) return null;
      const level = subcategory.levels.find((l) => l.score === Number(score));
      if (!level) return null;
      return level;
    }
    return null;
  }, [roadmapPulseScoreAssnState, categoryId, subcategoryId, score, updateScoreAssnsLevelLoading]);

  const assignedContentObjects = useMemo(() => {
    if (selectedScoreAssnLevel?.content?.length) {
      return selectedScoreAssnLevel.content.map((id) => ({ id, indexName: 'content' }));
    }
    return [];
  }, [selectedScoreAssnLevel?.content]);

  const assignedTrainingObjects = useMemo(() => {
    if (selectedScoreAssnLevel?.trainings?.length) {
      return selectedScoreAssnLevel.trainings.map((id) => ({ id, indexName: 'trainings' }));
    }
    return [];
  }, [selectedScoreAssnLevel?.trainings]);

  // Hooks
  const [assignedContentMetadata, assignedContentMetadataLoading, resetAssignedContentMetadata] = useGetObjectsAlgolia(
    assignedContentObjects as { id: string; indexName: string }[]
  );
  const [assignedTrainingMetadata, assignedTrainingMetadataLoading, resetAssignedTrainingMetadata] = useGetObjectsAlgolia(
    assignedTrainingObjects as { id: string; indexName: string }[]
  );

  function resetState() {
    setIsValidParams(false);
    setRoadmapPulseScoreAssnState([]);
    setSelectedSubcategoryName(null);
    setSelectedCategoryName(null);
    resetAssignedContentMetadata();
    resetAssignedTrainingMetadata();
  }

  function getDefaultCategory(roadmapPulseScoreAssn?: RoadmapPulseScoreAssnCategory[]) {
    return roadmapPulseScoreAssn ? roadmapPulseScoreAssn[0] : roadmapPulseScoreAssnState[0];
  }

  function getDefaultSubcategory(scoreAssns: RoadmapPulseScoreAssnCategory[] | null, categoryName: string) {
    const assns = scoreAssns ? scoreAssns : roadmapPulseScoreAssnState;
    const category = assns.find((c) => c.name === categoryName);
    if (!category) return null;
    return category.subCategories[0];
  }

  useEffect(() => {
    (async () => {
      try {
        resetState();

        // Get score assns
        const scoreAssnsQuery = await getRoadmapPulseScoreAssnsQuery({
          fetchPolicy: 'no-cache',
        });
        const scoreAssns: RoadmapPulseScoreAssnCategory[] = scoreAssnsQuery.data?.getRoadmapPulseScoreAssns.categories ?? [];
        if (!scoreAssns.length) {
          return;
        }

        setRoadmapPulseScoreAssnState(scoreAssns);

        // Set default category and subcategory
        if (categoryId && subcategoryId && score) {
          setIsValidParams(true);
          setSubCategoryName(scoreAssns, categoryId, subcategoryId);
        } else {
          const defaultCategory = getDefaultCategory(scoreAssns);
          const defaultCategoryName = defaultCategory.name;
          const defaultSubcategory = getDefaultSubcategory(scoreAssns, defaultCategoryName);
          const defaultSubcategoryName = defaultSubcategory?.name ?? '';
          setSubCategoryName(scoreAssns, defaultCategoryName, defaultSubcategoryName);
        }
      } catch (err) {
        console.error('ERROR - ScoreAssociations - useEffect():', err);
      }
    })();
  }, [categoryId, subcategoryId, score]);

  // Methods
  async function unassignScoreAssociationContent({
    categoryName,
    subCategoryName,
    scoreLevel,
    contentType,
    contentIDs,
  }: RoadmapPulseScoreAssnSubCategoryLevelInput) {
    try {
      if (!selectedScoreAssnLevel) return;
      const [contentID] = contentIDs as string[];
      const selectedContentIds = [];
      if (contentType === RoadmapPulseScoreAssnSubCategoryLevelContentType.CONTENT) {
        selectedContentIds.push(...selectedScoreAssnLevel.content);
      }
      if (contentType === RoadmapPulseScoreAssnSubCategoryLevelContentType.TRAININGS) {
        selectedContentIds.push(...selectedScoreAssnLevel.trainings);
      }
      const contentIndex = selectedContentIds.findIndex((id) => id === contentID);
      if (contentIndex === -1) return;
      selectedContentIds.splice(contentIndex, 1);
      const updatedScoreAssnLevelQuery = await updateScoreAssnsLevel({
        variables: {
          input: {
            categoryName,
            subCategoryName,
            contentType,
            contentIDs: selectedContentIds as string[],
            scoreLevel,
          },
        },
      });
      const updatedScoreAssnLevel = updatedScoreAssnLevelQuery.data?.updateRoadmapPulseScoreAssnsSubCategoryLevel;
      const updatedContentIds =
        contentType === RoadmapPulseScoreAssnSubCategoryLevelContentType.CONTENT ? updatedScoreAssnLevel?.content : updatedScoreAssnLevel?.trainings;
      updateLevelState({
        categoryName,
        subCategoryName,
        scoreLevel,
        contentIDs: updatedContentIds as string[],
        contentType,
      });
    } catch (err) {
      console.error('ERROR - ScoreAssociationsProvider - unassignScoreAssociationContent():', err);
      throw err;
    }
  }

  async function assignScoreAssociationContent(content: RoadmapPulseScoreAssnSubCategoryLevelInput) {
    try {
      if (!content.contentIDs || !content.categoryName || !content.subCategoryName) return;
      const updatedContentIds = [...content.contentIDs];
      if (selectedScoreAssnLevel) {
        if (content.contentType === RoadmapPulseScoreAssnSubCategoryLevelContentType.TRAININGS) {
          updatedContentIds.push(...(selectedScoreAssnLevel.trainings as string[]));
        }
        if (content.contentType === RoadmapPulseScoreAssnSubCategoryLevelContentType.CONTENT) {
          updatedContentIds.push(...(selectedScoreAssnLevel.content as string[]));
        }
      }
      const updatedScoreAssnLevelQuery = await updateScoreAssnsLevel({
        variables: {
          input: {
            categoryName: content.categoryName,
            subCategoryName: content.subCategoryName,
            contentType: content.contentType,
            contentIDs: updatedContentIds,
            scoreLevel: content.scoreLevel,
          },
        },
      });
      const updatedScoreAssnLevel = updatedScoreAssnLevelQuery.data?.updateRoadmapPulseScoreAssnsSubCategoryLevel;
      const contentIds =
        content.contentType === RoadmapPulseScoreAssnSubCategoryLevelContentType.CONTENT
          ? updatedScoreAssnLevel?.content
          : updatedScoreAssnLevel?.trainings;
      updateLevelState({
        categoryName: content.categoryName,
        subCategoryName: content.subCategoryName,
        scoreLevel: content.scoreLevel,
        contentIDs: contentIds as string[],
        contentType: content.contentType,
      });
    } catch (err) {
      console.error('ERROR - ScoreAssociationsProvider - assignScoreAssociation():', err);
      throw err;
    }
  }

  async function updateScoreAssociationFeedback({
    categoryName,
    subCategoryName,
    scoreLevel,
    contentType,
    feedbackTitle,
    feedbackText,
  }: RoadmapPulseScoreAssnSubCategoryLevelInput) {
    try {
      const updatedScoreAssnLevelQuery = await updateScoreAssnsLevel({
        variables: {
          input: {
            categoryName,
            subCategoryName,
            contentType,
            scoreLevel,
            feedbackTitle,
            feedbackText,
          },
        },
      });
      const updatedScoreAssnLevel = updatedScoreAssnLevelQuery.data?.updateRoadmapPulseScoreAssnsSubCategoryLevel;
      updateLevelState({
        categoryName,
        subCategoryName,
        scoreLevel,
        contentType,
        feedbackTitle: updatedScoreAssnLevel?.feedbackTitle,
        feedbackText: updatedScoreAssnLevel?.feedbackText,
      });
    } catch (err) {
      console.error('ERROR - ScoreAssociationsProvider - updateScoreAssociationFeedback():', err);
      throw err;
    }
  }

  function updateLevelState({
    categoryName,
    subCategoryName,
    scoreLevel,
    contentIDs,
    contentType,
    feedbackText,
    feedbackTitle,
  }: RoadmapPulseScoreAssnSubCategoryLevelInput) {
    setRoadmapPulseScoreAssnState((prevState) => {
      const state = JSON.parse(JSON.stringify(prevState)) as RoadmapPulseScoreAssnCategory[];
      const categoryIndex = state.findIndex((c) => c.name === categoryName);
      const subcategoryIndex = state[categoryIndex].subCategories.findIndex((s) => s.name === subCategoryName);
      const scoreIndex = state[categoryIndex].subCategories[subcategoryIndex].levels.findIndex((l) => l.score === scoreLevel);
      if (feedbackTitle) {
        state[categoryIndex].subCategories[subcategoryIndex].levels[scoreIndex].feedbackTitle = feedbackTitle;
      }
      if (feedbackText) {
        state[categoryIndex].subCategories[subcategoryIndex].levels[scoreIndex].feedbackText = feedbackText;
      }
      if (contentIDs) {
        if (contentType === RoadmapPulseScoreAssnSubCategoryLevelContentType.TRAININGS) {
          state[categoryIndex].subCategories[subcategoryIndex].levels[scoreIndex].trainings = [...contentIDs];
        } else if (contentType === RoadmapPulseScoreAssnSubCategoryLevelContentType.CONTENT) {
          state[categoryIndex].subCategories[subcategoryIndex].levels[scoreIndex].content = [...contentIDs];
        }
      }
      return state;
    });
  }

  function isCategoryValid(categoryName: string): boolean {
    return !!roadmapPulseScoreAssnState.find((c) => c.name === categoryName);
  }

  function isSubcategoryValid(categoryName: string, subcategoryName: string): boolean {
    const category = roadmapPulseScoreAssnState.find((c) => c.name === categoryName);
    if (!category) return false;
    const subcategory = category.subCategories.find((s) => s.name === subcategoryName);
    return !!subcategory;
  }

  function isLevelValid(level: string): boolean {
    if (Number.isNaN(level)) throw new Error('Level is NaN');
    const levelNum = Number(level);
    return levelNum >= 1 && levelNum <= 5;
  }

  function setSubCategoryName(scoreAssns: RoadmapPulseScoreAssnCategory[] | null, categoryName: string, subcategoryName: string) {
    const assns = scoreAssns ? scoreAssns : roadmapPulseScoreAssnState;
    const category = assns.find((c) => c.name === categoryName);
    if (!category) {
      setSelectedCategoryName(null);
      setSelectedSubcategoryName(null);
      return;
    }
    setSelectedCategoryName(categoryName);
    const subcategory = category.subCategories.find((s) => s.name === subcategoryName);
    if (!subcategory) {
      setSelectedSubcategoryName(null);
    }
    setSelectedSubcategoryName(subcategory?.name ?? '');
  }

  return (
    <ScoreAssociationsContext.Provider
      value={{
        roadmapPulseScoreAssns: roadmapPulseScoreAssnState,
        getRoadmapPulseScoreAssnsLoading,
        updateScoreAssnsLevelLoading,
        selectedCategoryName,
        selectedSubcategoryName,
        selectedScoreAssnLevel,
        isValidParams,
        assignedContentMetadata,
        assignedContentMetadataLoading,
        assignedTrainingMetadata,
        assignedTrainingMetadataLoading,
        selectedScoreAssnSubcategory,
        setSubCategoryName,
        isCategoryValid,
        isSubcategoryValid,
        isLevelValid,
        getDefaultCategory,
        getDefaultSubcategory,
        assignScoreAssociationContent,
        unassignScoreAssociationContent,
        updateScoreAssociationFeedback,
      }}
    >
      {children}
    </ScoreAssociationsContext.Provider>
  );
}
