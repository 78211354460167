import { ButtonGeneral, ButtonType, ButtonVariant } from '@components/general/button-general';
import { CompleteModal } from '@components/general/modals/complete-modal';
import { PulseScoreCircle } from '@components/roadmap/pulse-score-circle';
import { getRoadmapProgressColor } from '@utils/misc';
import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';

interface Props {
  sectionId: string;
  title: string;
  score: number;
  percent: number;
  contentPieces: number;
  trainingCourses: number;
  feedbackTitle?: string;
  feedbackDescription?: string;
  scoreColor: string;
  maxScore: number;
}

const PulseResultBlockPlan = ({
  sectionId,
  title,
  score,
  percent,
  contentPieces,
  trainingCourses,
  feedbackTitle,
  feedbackDescription,
  scoreColor,
  maxScore,
}: Props) => {
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const navigate = useNavigate();

  const canHaveRecommendations = contentPieces !== undefined && trainingCourses !== undefined;

  return (
    <View style={[styles.wrap, { borderTopColor: getRoadmapProgressColor((score / 10) * 100).color }]}>
      <View style={styles.topWrap}>
        <View style={styles.circularWrap} dataSet={{ media: ids.circularWrap }}>
          <PulseScoreCircle score={score} maxScore={maxScore} scoreColor={scoreColor} />
        </View>
        <View style={styles.contentWrap} dataSet={{ media: ids.contentWrap }}>
          <Text style={styles.percentageText}> {contentPieces === 0 && trainingCourses === 0 ? '-' : `${percent}%`}</Text>
          <View style={styles.progressWrap}>
            <View style={[styles.progressInner, { width: `${percent}%` }]} />
          </View>

          <Text style={styles.doneText}>
            <Text style={styles.bold}>{contentPieces}</Text> content pieces
            {'\n'}
            <Text style={styles.bold}>{trainingCourses}</Text> training courses
          </Text>
        </View>
      </View>
      <View style={styles.bottomWrap} dataSet={{ media: ids.bottomWrap }}>
        <View style={styles.titleWrap}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={styles.btnWrap}>
          {/* <View style={styles.eachButtonWrap}>
            <ButtonGeneral
              label="Continue"
              type={ButtonType.user}
              variant={ButtonVariant.Secondary}
              // onPress={() => setIsFeedbackModalVisible(true)}
            />
          </View> */}
          <View style={styles.eachButtonWrap}>
            <ButtonGeneral label="View plan" type={ButtonType.user} onPress={() => navigate(`${sectionId}`)} />
          </View>
        </View>
      </View>
      <CompleteModal
        isVisible={isFeedbackModalVisible}
        onClose={() => setIsFeedbackModalVisible(false)}
        title={feedbackTitle || ''}
        htmlString={feedbackDescription}
      />
    </View>
  );
};

export { PulseResultBlockPlan };
